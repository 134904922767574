<!--
 * @Author: 月魂
 * @Date: 2021-11-16 13:23:03
 * @LastEditTime: 2022-09-20 16:36:19
 * @LastEditors: 月魂
 * @Description: 艺术节介绍
 * @FilePath: \silk-road\src\views\Intro.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img src="http://image.wearetogether.com.cn/8th/assets/intro/header.png" alt="" class="header-img">
      <van-icon name="arrow-left" class="back" @click="handleBack" />
    </div>
    <video v-if="$t('intro.videoUrl')" controls class="video" :poster="$t('intro.posterUrl')">
      <source :src="$t('intro.videoUrl')" >
    </video>
    <div class="cont">
      <div class="about">
        <div class="title">
          {{ this.$t('intro.title') }}
        </div>
        <!-- <b class="intro">{{ this.$t('intro.name') }}</b> -->
        <div class="intro">
          <div class="intro-des" v-for="(text, index) in this.$t('intro.des')" :key="index">{{ text }}</div>
        </div>
      </div>
      <!-- 历届艺术节推文 -->
      <!-- <div class="host">
        <div class="inner-host">
          <div class="host-title">
            {{ this.$t('params.history') }}
          </div>
          <div class="host-cont">
            <div class="img"></div>
          </div>
        </div>
      </div> -->

      <!-- 合作伙伴 -->
      <!-- <div class="partner">
        <div class="partner-title">
          <span class="color"></span>
          <span class="text">合作伙伴</span>
        </div>
        <div class="list">
          <div class="item">
            <div class="img"></div>
            <div class="text">电竞专区</div>
          </div>
          <div class="item">
            <div class="img"></div>
            <div class="text">电竞专区</div>
          </div>
          <div class="item">
            <div class="img"></div>
            <div class="text">电竞专区</div>
          </div>
          <div class="item">
            <div class="img"></div>
            <div class="text">电竞专区</div>
          </div>
        </div>
      </div> -->
    </div>
    
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { handleBack } from '../utils/util'
Vue.use(Icon)

export default {
  name: 'Intro',
  mounted() {
    this.wxShareDef();
  },
  methods: {
    handleBack() {
      handleBack()
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #FCF8ED;
  .header {
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      width: 24px;
      margin: auto;
      color: #fff;
      line-height: 44px;
    }
  }
  .video {
    display: block;
    width: 100%;
    video {
      object-fit: fill;
    }
  }
  .cont {
    margin-top: 16px;
    // background-image: linear-gradient(to right, #ec6e5b, #b27c9e);
    .about {
      max-width: 1200PX;
      margin: auto;
      padding-bottom: 30px;
      // color: #fff;
      .title {
        font-family: SourceHanSerifCN-Bold;
        color: #4D4D4D;
        text-align: center;
        padding-top: 8px;
        font-size: 20px;
      }
      .intro {
        font-family: SourceHanSansCN-Regular;
        padding: 0 16px;
        font-size: 14px;
        line-height: 24px;
        margin-top: 16px;
        color: #4D4D4D;
        text-indent: 2em;
        .intro-des {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  // 主办介绍
  .host {
    padding: 10px;
    padding-bottom: 30px;
    background-image: linear-gradient(to right, #db6766, #a574a6);
    color: #fff;
    .inner-host {
      max-width: 1200PX;
      margin: auto;
      .host-title {
        color: #fff;
      }
      .host-cont {
        margin-top: 10px;
        .img {
          width: 100%;
          height: 100px;
          background-color: crimson;
        }
        .text {
          width: 100%;
          margin-top: 10px;
          letter-spacing: 1px;
          line-height: 1.5;
          font-size: 14px;
        }
      }
    }
  }
  // 合作伙伴
  // .partner {
  //   padding: 10px 20px;
  //   .partner-title {
  //     border: 1px solid green;
  //     width: 150px;
  //     border-radius: 6px;
  //     display: flex;
  //     .color {
  //       width: 40px;
  //       height: 30px;
  //       background-color: green;
  //     }
  //     .text {
  //       font-size: 14px;
  //       line-height: 30px;
  //       padding: 0 10px;
  //       color: green;
  //     }
  //   }
  //   .list {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     margin-top: 10px;
  //     .item {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       width: 48%;
  //       background-color: darkgreen;
  //       padding: 20px 10px;
  //       border-radius: 10px;
  //       margin-bottom: 10px;
  //       .img {
  //         width: 50px;
  //         height: 50px;
  //         border-radius: 50%;
  //         background-color: #fff;
  //         margin-right: 6px;
  //       }
  //       .text {
  //         color: #fff;
  //         font-size: 16px;
  //         font-weight: 600;
  //         letter-spacing: 1px;
  //       }
  //     }
  //   }
  // }
}
</style>
